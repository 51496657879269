/// Built so Editors have a way to modify global
/// components like the Site Header and Site Footer

import * as React from "react"

import DynamicGlobalComponent from "../components/dynamicGlobalComponent"
import useStoryblok from "../../src/lib/storyblok"

const GlobalPage = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  let components = [];
  if(story.content && story.content.body) {
    components = story.content.body.map((blok) => {
      return <DynamicGlobalComponent blok={blok} key={blok["_uid"]} />
    })
  }

  return (
    <div className="body-wrapper">
      {components}
    </div>
  )
}

export default GlobalPage